import * as React from "react";
import Button from "@mui/material/Button";
import {
  Backdrop,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { IPaymentState, PaymentReducer, departments } from "./ExpenseReducer";
import useToken from "../../useToken";
import jwt_decode from "jwt-decode";
import { ISignInData } from "../../../services/API/signin";
import { format, parseISO } from "date-fns";
import {
  DeleteExpense,
  IExpenseRequest,
  IExpenseRequestReject,
  IExpenseRequestUpdate,
  addExpenseRequest,
  rejectExpenseRequest,
  updateExpenseRequest,
} from "../../../services/API/AddEditExpense";
import { IExpenseRequestRow } from "../../../services/API/GetExpenseRequests";
import GetExpenseRequestList, {
  IExpenseRequestListRow,
} from "../../../services/API/GetExpenseRequestList";
import GetExpenseTypesList, {
  IGetExpenseTypesListRow,
} from "../../../services/API/GetExpenseTypesList";
import GetRequestAssignTo, {
  IGetRequestAssignToRow,
} from "../../../services/API/GetRequestAssignTo";
import uploadFile, { IUploadFile } from "../../../services/API/UploadFile";
import { generateRandomString } from "../../../Common/generateRandomString";
import GetImage from "../../../services/API/GetImage";
import GetOtherDocs from "../../../services/API/GetOtherDocs";
import SignatureCapture from "./SignatureCapture";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  BootstrapDialogTitle,
  SlimScrollbarDialogContent,
} from "../../../Common/BootstrapDialogTitle";

interface IAddNewProps {
  openUser: boolean;
  onClose: (val?: boolean) => void;
  requestRow?: IExpenseRequestRow;
}
interface IRejectClose {
  open: boolean;
  type: number;
}
export default function AddExpenseRequest(props: IAddNewProps) {
  const canProceed = (state: IPaymentState) => {
    let val = true;

    if (state.dataToSave.requestType === "") {
      val = false;
    }
    if (
      props.requestRow &&
      (state.dataToSave.choice === "" || state.dataToSave.choice === "N/A")
    ) {
      val = false;
    }
    if (state.dataToSave.requestAssigned === "") {
      val = false;
    }
    if (state.dataToSave.requestAmount <= 0) {
      val = false;
    }
    if (state.dataToSave.department === "") {
      val = false;
    }

    if (state.dataToSave.requestBy === "") {
      val = false;
    }

    if (state.dataToSave.edrNo === "") {
      val = false;
    }
    if (state.dataToSave.purpose === "") {
      val = false;
    }

    return val;
  };
  const token = useToken().token;
  const {
    state,
    handleChange,
    handleChangeDropDown,
    updateStateForExistingUser,
  } = PaymentReducer();
  const [loading, setLoading] = React.useState(false);
  const [displayError, setDisplayError] = React.useState<boolean>(false);
  const userData: ISignInData = jwt_decode(token);
  const [requestData, setRequestData] = React.useState<
    IGetExpenseTypesListRow[] | undefined
  >(undefined);
  const [assignedTo, setAssignedTo] = React.useState<
    IGetRequestAssignToRow[] | undefined
  >(undefined);
  const [filesToUpload, setFilesToUpload] = React.useState<File[]>([]);
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const [displayReceipt, setDisplayReceipt] = React.useState<boolean>(false);
  const [filesToDisplay, setFilesToDisplay] = React.useState<
    { url: string; isImage: boolean }[] | undefined
  >();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState("");
  const [openReject, setOpenReject] = React.useState<IRejectClose>({
    open: false,
    type: 0,
  });
  const [rejectReason, setRejectReason] = React.useState<string>("");
  const [showSignatureCapture, setShowSignatureCapture] =
    React.useState<boolean>(false);
  const [viewSig, setViewSig] = React.useState(false);
  const [warningOpen, setWarningOpen] = React.useState(false);
  const [approvedOpen, setApproveOpen] = React.useState(false);
  const allEditBeforeApprove =
    !props.requestRow?.approvedBy &&
    userData.CanApproveExpense === "True" &&
    !props.requestRow?.queried;

  const handleClickOpen = () => {
    setViewSig(true);
  };

  const handleCloseSig = () => {
    setViewSig(false);
  };

  const handleApprovePopUp = () => {
    setApproveOpen(true);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      if (props.requestRow && props.requestRow?.exRequestDocRef) {
        try {
          const claimEvidenceResponse = await GetOtherDocs({
            token,
            OtherDocs: props.requestRow?.exRequestDocRef,
            PolicyNo: props.requestRow?.exRequestDocRef,
          });

          if (claimEvidenceResponse) {
            const claimEvidenceData = claimEvidenceResponse.data;

            // Assuming claimEvidenceData is an array of rows
            const filePromises = claimEvidenceData.map(async (row) => {
              const imageResponse = await GetImage({
                token,
                subfolder: row.uploadFileTypes,
                imageName: row.filesToShow,
              });

              if (imageResponse) {
                const fileType = row.filesToShow.toLowerCase().endsWith(".pdf")
                  ? "application/pdf"
                  : "image/jpeg" || "image/png";
                const blob = new Blob([imageResponse.data], { type: fileType });
                const fileUrl = URL.createObjectURL(blob);
                return { url: fileUrl, isImage: checkIfImage(row.filesToShow) };
              }

              return null; // or handle error scenario if needed
            });

            // Wait for all promises to resolve
            const filesData = await Promise.all(filePromises);

            // Filter out null values (failed requests)
            const validFilesData = filesData.filter(
              (file) => file !== null
            ) as { url: string; isImage: boolean }[];

            setFilesToDisplay(validFilesData);
          }
        } catch (error) {
          // Handle errors here
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchData();
  }, [token, props.requestRow]);

  const formatDate = (dateInput: Date) => {
    let date;

    if (typeof dateInput === "string") {
      date = parseISO(dateInput);
    } else if (dateInput instanceof Date) {
      date = dateInput;
    } else {
      return "";
    }

    return isNaN(date.getTime()) ? "" : format(date, "dd-MM-yyyy");
  };

  React.useEffect(() => {
    async function loadExpense() {
      const data = await GetExpenseTypesList({ token });
      setRequestData(data?.data);
    }
    if (!props.requestRow) {
      loadExpense();
    }
  }, []);

  React.useEffect(() => {
    async function loadExpense() {
      const requestID = Number(state.dataToSave.requestType);
      const data = await GetRequestAssignTo({ token, requestID });
      setAssignedTo(data?.data);
    }
    if (!props.requestRow) {
      loadExpense();
    }
  }, [state.dataToSave.requestType, token]);

  React.useEffect(() => {
    async function loadData() {
      if (props.requestRow) {
        const requestID = props.requestRow.expenseRequestID;
        const data = await GetExpenseRequestList({ token, requestID });
        const dataTosend: IExpenseRequestListRow[] | [] = data ? data.data : [];
        await updateStateForExistingUser(props.requestRow, dataTosend);
      }
    }
    loadData();
  }, [props.requestRow, token]);

  const saveData = async () => {
    setLoading(true);
    const states = state.dataToSave;
    const items = [
      {
        itemName: states.item1,
        quantity: states.quantity1,
        unitCost: states.unitCost1,
        amount: states.amount1,
      },
      {
        itemName: states.item2,
        quantity: states.quantity2,
        unitCost: states.unitCost2,
        amount: states.amount2,
      },
      {
        itemName: states.item3,
        quantity: states.quantity3,
        unitCost: states.unitCost3,
        amount: states.amount3,
      },
    ];
    let uniqueClaimRef = generateRandomString(15);
    if (props.requestRow?.exRequestDocRef) {
      uniqueClaimRef = props.requestRow?.exRequestDocRef;
    }
    const dataToSend: IExpenseRequest = {
      choice: state.dataToSave.choice === "" ? "N/A" : states.choice,
      department: states.department,
      requestBy: states.requestBy,
      requestDate: states.date,
      edrNo: states.edrNo,
      purpose: states.purpose,
      dateLastPurchased: states.dateLastPurchased,
      requestAmount: states.requestAmount,
      requestAmountGST: states.requestAmountGST,
      preparedBy: userData.DisplayName,
      EmployeeID: userData.EmployeeID,
      items: items,
      token: token,
      chequeNo: states.chequeNo,
      bank: states.bank,
      extraInfo: states.extraInfo,
      requestType: Number(states.requestType),
      requestAssigned: Number(states.requestAssigned),
      exRequestDocRef: uniqueClaimRef,
    };

    if (filesToUpload.length > 0) {
      for (let i = 0; i < filesToUpload.length; i++) {
        const dataToUpload: IUploadFile = {
          file: filesToUpload[i],
          policy: uniqueClaimRef,
          UploadFileTypeID: 15,
          token: token,
          OtherDocs: uniqueClaimRef,
        };
        await uploadFile(dataToUpload);
      }
    }

    const result = await addExpenseRequest(dataToSend);
    if (result === "Inserted successfully") {
      setLoading(false);
      props.onClose(true);
    }
  };

  const handleSubmit = async () => {
    if (!canProceed(state)) {
      setDisplayError(true);
    } else if (filesToUpload.length < 1) {
      setWarningOpen(true);
    } else {
      saveData();
    }
  };

  const handleWarningBack = () => {
    setWarningOpen(false);
  };
  const handleApprovBack = () => {
    setApproveOpen(false);
  };

  const handleContinueWithoutReceipt = () => {
    setWarningOpen(false);
    saveData();
  };
  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFilesToUpload(Array.from(files));
    }
  };
  const handleShowFiles = () => {
    setDisplayReceipt(true);
  };

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseDialogMain = () => {
    setDisplayReceipt(false);
  };
  const handleStage = async (
    stage: number,
    signature?: string,
    receivedByName?: string
  ) => {
    if (!canProceed(state)) {
      setDisplayError(true);
    } else {
      setLoading(true);
      const states = state.dataToSave;
      const items = [
        {
          itemID: states.itemID1,
          itemName: states.item1,
          quantity: states.quantity1,
          unitCost: states.unitCost1,
          amount: states.amount1,
        },
        {
          itemID: states.itemID2,
          itemName: states.item2,
          quantity: states.quantity2,
          unitCost: states.unitCost2,
          amount: states.amount2,
        },
        {
          itemID: states.itemID3,
          itemName: states.item3,
          quantity: states.quantity3,
          unitCost: states.unitCost3,
          amount: states.amount3,
        },
      ];
      let uniqueClaimRef = generateRandomString(15);
      if (props.requestRow?.exRequestDocRef) {
        uniqueClaimRef = props.requestRow?.exRequestDocRef;
      }
      const dataToSend: IExpenseRequestUpdate = {
        requestID: state.dataToSave.expenseRequestID,
        choice: state.dataToSave.choice,
        department: states.department,
        edrNo: states.edrNo,
        purpose: states.purpose,
        dateLastPurchased: states.dateLastPurchased,
        requestAmount: states.requestAmount,
        requestAmountGST: states.requestAmountGST,
        items: items,
        token: token,
        chequeNo: states.chequeNo,
        bank: states.bank,
        extraInfo: states.extraInfo,
        exRequestDocRef: uniqueClaimRef,
        passedBy: stage === 2 ? userData.DisplayName : "N/A",
        passedDate: stage === 2 ? new Date() : "N/A",
        approvedBy: stage === 3 ? userData.DisplayName : "N/A",
        approvedDate: stage === 3 ? new Date() : "N/A",
        receivedBy: stage === 4 ? receivedByName : "N/A",
        receivedDate: stage === 4 ? new Date() : "N/A",
        receiverSignature: stage === 4 ? signature : "N/A",
        approving: stage === 3,
      };

      if (filesToUpload.length > 0) {
        for (let i = 0; i < filesToUpload.length; i++) {
          const dataToUpload: IUploadFile = {
            file: filesToUpload[i],
            policy: uniqueClaimRef,
            UploadFileTypeID: 15,
            token: token,
            OtherDocs: uniqueClaimRef,
          };
          await uploadFile(dataToUpload);
        }
      }

      const result = await updateExpenseRequest(dataToSend);
      if (result === "Updated successfully") {
        setLoading(false);
        props.onClose(true);
      }
    }
  };

  const handleSignature = () => {
    setShowSignatureCapture(true);
  };

  const handleSave = async (dataUrl: string, receiver: string) => {
    setShowSignatureCapture(false);
    handleStage(4, dataUrl, receiver);
  };

  const handleReject = async (type: number) => {
    if (rejectReason !== "" && state.dataToSave.expenseRequestID) {
      //We are closing or rejecting request
      setLoading(true);
      const dataToSend: IExpenseRequestReject = {
        requestID: state.dataToSave.expenseRequestID,
        token,
        rejectReason,
        enteredBy: userData.DisplayName,
        isRejected: type,
      };
      const result = await rejectExpenseRequest(dataToSend);
      if (result === "Rejected Successfully") {
        setLoading(false);
        props.onClose(true);
      }
    }
  };

  const handleDelete = async () => {
    if (state.dataToSave.expenseRequestID) {
      await DeleteExpense(token, state.dataToSave.expenseRequestID);
      setLoading(false);
      props.onClose(true);
    }
  };

  const handleClose = () => {
    setShowSignatureCapture(false);
  };
  const returnStatusColor = () => {
    let statusColor = "red"; // Default color
    if (props.requestRow) {
      if (props.requestRow.requestStatus === "Awaiting Review") {
        statusColor = "orange"; // A brighter blue for better visibility
      } else if (props.requestRow.requestStatus === "Awaiting Approval") {
        statusColor = "dodgerblue"; // A brighter blue for better visibility
      } else if (props.requestRow.requestStatus === "Approved") {
        statusColor = "forestgreen"; // A deep green for a positive status
      } else if (props.requestRow.requestStatus === "Completed") {
        statusColor = "mediumpurple"; // A medium purple for distinction
      } else if (props.requestRow.requestStatus === "Rejected") {
        statusColor = "crimson"; // A strong red for alert
      } else if (props.requestRow.requestStatus === "Closed") {
        statusColor = "dimgray"; // A medium gray for a neutral status
      } else if (props.requestRow.requestStatus === "Queried") {
        statusColor = "#088da5"; // A distinct color for the Queried status
      }
    }
    return statusColor;
  };

  return (
    <Dialog
      open={props.openUser}
      maxWidth="md"
      disableEscapeKeyDown={true}
      hideBackdrop={true}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={props.onClose}
        headerColor={returnStatusColor()}
        expenseData={props.requestRow ?? undefined}
      >
        {props.requestRow ? "Expense Request" : "Add Expense Request"}
      </BootstrapDialogTitle>
      <SlimScrollbarDialogContent>
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <form>
          <Grid container spacing={0.5} sx={{ padding: 2 }}>
            <Grid item xs={4}>
              {requestData ? (
                <FormControl fullWidth>
                  <InputLabel id="dropdown-label">Request Type</InputLabel>
                  <Select
                    labelId="dropdown-label"
                    name="requestType"
                    value={state.dataToSave.requestType || ""}
                    label="Request Type"
                    onChange={handleChangeDropDown}
                    disabled={!requestData || requestData.length === 0}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 250,
                        },
                      },
                    }}
                  >
                    {requestData.map((item) => (
                      <MenuItem
                        key={item.requestID}
                        value={item.requestID}
                        style={{
                          fontSize: "12px",
                          padding: "5px 12px",
                          lineHeight: "1",
                        }}
                      >
                        {item.requestType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  fullWidth
                  value={state.dataToSave.requestType}
                  disabled={true}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {assignedTo ? (
                <FormControl fullWidth>
                  <InputLabel id="dropdown-label">Sent To</InputLabel>
                  <Select
                    labelId="dropdown-label"
                    name="requestAssigned"
                    defaultValue={state.dataToSave.requestAssigned}
                    label="Sent To"
                    onChange={handleChangeDropDown}
                  >
                    {assignedTo.map((item) => (
                      <MenuItem key={item.employeeID} value={item.employeeID}>
                        {item.fullName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  fullWidth
                  value={state.dataToSave.requestAssigned}
                  disabled={true}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="dropdown-label">Expense Type</InputLabel>
                <Select
                  labelId="dropdown-label"
                  name="choice"
                  value={state.dataToSave.choice}
                  label="Expense Type"
                  onChange={handleChangeDropDown}
                  disabled={
                    props.requestRow === undefined || state.dataToSave.editing
                  }
                >
                  <MenuItem value="Building Imprest">Building Imprest</MenuItem>
                  <MenuItem value="Office Expenditure - Life">
                    Office Expenditure - Life
                  </MenuItem>
                  <MenuItem value="Office Expenditure Non-Life">
                    Office Expenditure Non-Life
                  </MenuItem>
                  <MenuItem value="Office Expenditure General - NL">
                    Office Expenditure General - NL
                  </MenuItem>
                  <MenuItem value="Single Cheque Voucher">
                    Single Cheque Voucher
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <FormControl fullWidth>
                <InputLabel id="department-label">Department</InputLabel>
                <Select
                  labelId="department-label"
                  name="department"
                  value={state.dataToSave.department}
                  onChange={handleChangeDropDown}
                  disabled={state.dataToSave.editing}
                >
                  {departments.map((dept, index) => (
                    <MenuItem key={index} value={dept}>
                      {dept}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                fullWidth
                id="bwipvch"
                name="bwipvch"
                label="BWIP VCH#"
                value={state.dataToSave.bwipvch}
                disabled={true}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="date"
                name="date"
                inputProps={{ maxLength: 20 }}
                value={format(new Date(state.dataToSave.date), "yyyy-MM-dd")}
                label="Date"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={true}
              />
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                id="edrNo"
                name="edrNo"
                fullWidth
                label="EDR No"
                value={state.dataToSave.edrNo}
                onChange={handleChange}
                disabled={state.dataToSave.editing}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="requestBy"
                name="requestBy"
                fullWidth
                label="Requested By"
                value={state.dataToSave.requestBy}
                onChange={handleChange}
                disabled={state.dataToSave.editing}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="dateLastPurchased"
                name="dateLastPurchased"
                value={format(
                  new Date(state.dataToSave.dateLastPurchased),
                  "yyyy-MM-dd"
                )}
                label="Date Last Purchased"
                type="date"
                onKeyDown={onKeyDown}
                fullWidth
                onChange={handleChange}
                disabled={state.dataToSave.editing}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                id="bank"
                name="bank"
                label="Bank"
                value={state.dataToSave.bank}
                onChange={handleChange}
                disabled={state.dataToSave.editing}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                id="cheque"
                name="chequeNo"
                label="Cheque#"
                value={state.dataToSave.chequeNo}
                onChange={handleChange}
                disabled={state.dataToSave.editing}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="purpose"
                name="purpose"
                fullWidth
                label="Description"
                value={state.dataToSave.purpose}
                onChange={handleChange}
                disabled={
                  allEditBeforeApprove ? false : state.dataToSave.editing
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Item"
                name="item1"
                value={state.dataToSave.item1}
                onChange={handleChange}
                disabled={
                  allEditBeforeApprove ? false : state.dataToSave.editing
                }
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                label="Quantity"
                name="quantity1"
                type="number"
                value={state.dataToSave.quantity1}
                onChange={handleChange}
                disabled={
                  allEditBeforeApprove ? false : state.dataToSave.editing
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Unit Cost"
                name="unitCost1"
                type="number"
                value={state.dataToSave.unitCost1}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="caption">SLE</Typography>
                    </InputAdornment>
                  ),
                }}
                disabled={
                  allEditBeforeApprove ? false : state.dataToSave.editing
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Amount"
                value={state.dataToSave.quantity1 * state.dataToSave.unitCost1}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="caption">SLE</Typography>
                    </InputAdornment>
                  ),
                }}
                disabled
              />
            </Grid>

            {/* Second Set */}
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Item"
                name="item2"
                value={state.dataToSave.item2}
                onChange={handleChange}
                disabled={
                  allEditBeforeApprove ? false : state.dataToSave.editing
                }
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                label="Quantity"
                name="quantity2"
                type="number"
                value={state.dataToSave.quantity2}
                onChange={handleChange}
                disabled={
                  allEditBeforeApprove ? false : state.dataToSave.editing
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Unit Cost"
                name="unitCost2"
                type="number"
                value={state.dataToSave.unitCost2}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="caption">SLE</Typography>
                    </InputAdornment>
                  ),
                }}
                disabled={
                  allEditBeforeApprove ? false : state.dataToSave.editing
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Amount"
                value={state.dataToSave.quantity2 * state.dataToSave.unitCost2}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="caption">SLE</Typography>
                    </InputAdornment>
                  ),
                }}
                disabled
              />
            </Grid>

            {/* Third Set */}
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Item"
                name="item3"
                value={state.dataToSave.item3}
                onChange={handleChange}
                disabled={
                  allEditBeforeApprove ? false : state.dataToSave.editing
                }
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                label="Quantity"
                name="quantity3"
                type="number"
                value={state.dataToSave.quantity3}
                onChange={handleChange}
                disabled={
                  allEditBeforeApprove ? false : state.dataToSave.editing
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Unit Cost"
                name="unitCost3"
                type="number"
                value={state.dataToSave.unitCost3}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="caption">SLE</Typography>
                    </InputAdornment>
                  ),
                }}
                disabled={
                  allEditBeforeApprove ? false : state.dataToSave.editing
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Amount"
                value={state.dataToSave.quantity3 * state.dataToSave.unitCost3}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="caption">SLE</Typography>
                    </InputAdornment>
                  ),
                }}
                disabled
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                id="requestAmount"
                name="requestAmount"
                type="number"
                fullWidth
                label="Request Amount"
                value={state.dataToSave.requestAmount}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="caption">SLE</Typography>
                    </InputAdornment>
                  ),
                }}
                disabled={
                  allEditBeforeApprove ? false : state.dataToSave.editing
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="requestAmountGST"
                name="requestAmountGST"
                type="number"
                fullWidth
                label="GST Amount"
                value={state.dataToSave.requestAmountGST}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="caption">SLE</Typography>
                    </InputAdornment>
                  ),
                }}
                disabled={
                  allEditBeforeApprove ? false : state.dataToSave.editing
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Prepared By"
                value={state.dataToSave.preparedBy || userData.DisplayName}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="extraInfo"
                name="extraInfo"
                fullWidth
                label="Extra Details"
                value={state.dataToSave.extraInfo}
                onChange={handleChange}
                disabled={
                  allEditBeforeApprove ? false : state.dataToSave.editing
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Passed By"
                value={`${state.dataToSave.passedBy ?? ""} ${
                  props.requestRow?.passedDate
                    ? `on ${formatDate(props.requestRow.passedDate).slice(
                        0,
                        10
                      )}`
                    : ""
                }`}
                disabled={true}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Approved By"
                value={`${state.dataToSave.approvedBy ?? ""} ${
                  props.requestRow?.approvedDate
                    ? `on ${formatDate(props.requestRow.approvedDate).slice(
                        0,
                        10
                      )}`
                    : ""
                }`}
                disabled={true}
              />
            </Grid>
            <Grid item xs={state.dataToSave.receiverSignature ? 3.5 : 4}>
              <TextField
                fullWidth
                label="Received By"
                value={`${state.dataToSave.receivedBy ?? ""} ${
                  props.requestRow?.receivedDate
                    ? `on ${formatDate(props.requestRow.receivedDate).slice(
                        0,
                        10
                      )}`
                    : ""
                }`}
                disabled={true}
              />
            </Grid>
            {state.dataToSave.receiverSignature && (
              <Grid item xs={0.5}>
                <Tooltip title="View Signature">
                  <IconButton onClick={handleClickOpen}>
                    <VisibilityIcon sx={{ color: "blue" }} />
                  </IconButton>
                </Tooltip>
                <Dialog open={viewSig} onClose={handleCloseSig}>
                  <DialogTitle>Receiver's Signature</DialogTitle>
                  <DialogContent>
                    <img
                      src={state.dataToSave.receiverSignature}
                      alt="Receiver's Signature"
                      style={{ maxWidth: "100%" }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseSig} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            )}
            {!state.dataToSave.closed && (
              <Grid item xs={12}>
                {!state.dataToSave.exRequestDocRef ? (
                  <>
                    <input
                      type="file"
                      accept=".jpeg, .jpg, .png"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={handleFileInputChange}
                      multiple
                    />
                    <Button
                      variant="outlined"
                      onClick={handleUploadButtonClick}
                      style={{ marginRight: "10px" }}
                      disabled={state.dataToSave.editing}
                    >
                      Upload Files
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={handleShowFiles}
                    style={{ marginRight: "10px" }}
                  >
                    {`${
                      filesToDisplay && filesToDisplay.length
                    } File(s) to View`}
                  </Button>
                )}
                {!props.requestRow && (
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={handleSubmit}
                    disabled={loading || props.requestRow !== undefined}
                  >
                    Submit
                  </Button>
                )}
                {state.dataToSave.preparedBy !== "" &&
                  !props.requestRow?.passedBy &&
                  userData.CanReviewExpense === "True" &&
                  Number(userData.EmployeeID) !==
                    Number(props.requestRow?.requestAssignedID) && (
                    <>
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={(e) => handleStage(2)}
                        style={{ marginRight: "10px" }}
                      >
                        Review/Pass
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={(e) => handleDelete()}
                        style={{ marginRight: "10px" }}
                        disabled={state.dataToSave.rejected}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                {state.dataToSave.rejected && (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={(e) => setOpenReject({ open: true, type: 2 })}
                  >
                    Close
                  </Button>
                )}
                {props.requestRow?.passedBy &&
                  !props.requestRow?.approvedBy &&
                  userData.CanApproveExpense === "True" &&
                  !props.requestRow.queried &&
                  props.requestRow?.requestAmount <=
                    Number(userData.ApprovalLimit) && (
                    <>
                      <Button
                        variant="outlined"
                        color="inherit"
                        onClick={(e) => setOpenReject({ open: true, type: 3 })}
                        style={{ marginRight: "10px" }}
                      >
                        Query
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={(e) => setOpenReject({ open: true, type: 1 })}
                        style={{ marginRight: "10px" }}
                      >
                        Reject
                      </Button>
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={handleApprovePopUp}
                      >
                        {`${state.isDirty ? "Save & " : ""}Approve`}
                      </Button>
                    </>
                  )}
                {props.requestRow?.approvedBy &&
                  props.requestRow?.passedBy &&
                  !props.requestRow?.receivedBy && (
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={handleSignature}
                    >
                      Received Funds
                    </Button>
                  )}
              </Grid>
            )}
          </Grid>
          {displayError && (
            <DialogContentText color={"red"} sx={{ paddingLeft: 1 }}>
              Please make sure all fields are completed
            </DialogContentText>
          )}
          {displayReceipt && filesToDisplay && filesToDisplay.length > 0 && (
            <Dialog open={displayReceipt} onClose={handleCloseDialogMain}>
              <DialogTitle>Supporting Documents</DialogTitle>
              <Grid container spacing={2}>
                {filesToDisplay &&
                  filesToDisplay.map((imageUrl, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                      <Card>
                        {imageUrl.isImage ? (
                          <CardActionArea
                            onClick={() => handleImageClick(imageUrl.url)}
                          >
                            <CardMedia
                              component="img"
                              alt={`Image ${index + 1}`}
                              height="140"
                              image={imageUrl.url}
                            />
                          </CardActionArea>
                        ) : (
                          <CardContent>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              {`File ${index + 1}`}
                            </Typography>
                            <Button
                              onClick={() => handleDownload(imageUrl.url)}
                            >
                              Download
                            </Button>
                          </CardContent>
                        )}
                      </Card>
                    </Grid>
                  ))}
              </Grid>
              <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                  <img
                    src={selectedImage}
                    alt="Expanded"
                    style={{ width: "100%", height: "auto" }}
                  />
                </DialogContent>
              </Dialog>
            </Dialog>
          )}
          {openReject.open && (
            <Dialog
              open={openReject.open}
              onClose={(e) => setOpenReject({ open: false, type: 0 })}
            >
              <DialogTitle>{`${
                openReject.type === 1
                  ? "Reject"
                  : openReject.type === 3
                  ? "Query"
                  : "Close"
              } Request?`}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {`Please enter the reason for ${
                    openReject.type === 1
                      ? "rejecting"
                      : openReject.type === 3
                      ? "querying"
                      : "closing"
                  } this request.`}
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="rejectReason"
                  label={`${
                    openReject.type === 1
                      ? "Reject"
                      : openReject.type === 3
                      ? "Query"
                      : "Close"
                  } Request`}
                  type="text"
                  fullWidth
                  variant="standard"
                  value={rejectReason}
                  onChange={(e) => setRejectReason(e.target.value)}
                  multiline
                  rows={3}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={(e) => setOpenReject({ open: false, type: 0 })}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={(e) => handleReject(openReject.type)}
                  color="secondary"
                  disabled={rejectReason.length < 10}
                >
                  {`${
                    openReject.type === 1
                      ? "Reject"
                      : openReject.type === 3
                      ? "Query"
                      : "Close"
                  } Request`}
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {showSignatureCapture && (
            <SignatureCapture onSave={handleSave} onClose={handleClose} />
          )}
          <Dialog open={warningOpen} onClose={handleWarningBack}>
            <DialogTitle>Warning</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You haven't added an image for the receipt. This request might
                be rejected.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleWarningBack} color="primary">
                Back
              </Button>
              <Button
                onClick={handleContinueWithoutReceipt}
                disabled={loading}
                color="secondary"
              >
                Continue without Receipt
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={approvedOpen} onClose={handleApprovBack}>
            <DialogTitle>Information</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You are about to {state.isDirty ? "save changes and " : ""}
                approve{" "}
                <strong>
                  {`${Number(
                    state.dataToSave.requestAmount
                      ? state.dataToSave.requestAmount
                      : 0
                  ).toFixed(2)} SLE`}
                </strong>{" "}
                from the <strong>{state.dataToSave.choice}</strong> budget.{" "}
                {state.isDirty
                  ? "This action will update the data and adjust the balance accordingly."
                  : "This action will adjust the balance accordingly."}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleApprovBack} color="primary">
                No
              </Button>
              <Button
                onClick={(e) => {
                  handleStage(3);
                }}
                color="secondary"
                disabled={loading}
              >
                Approve
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </SlimScrollbarDialogContent>
    </Dialog>
  );
}
const checkIfImage = (fileName: string): boolean => {
  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp"]; // Add more if needed
  const lowerCaseFileName = fileName.toLowerCase();
  return imageExtensions.some((ext) => lowerCaseFileName.endsWith(ext));
};

const handleDownload = (fileUrl: string) => {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = getFileNameFromUrl(fileUrl); // Assuming you have a function to extract the file name from the URL
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const getFileNameFromUrl = (url: string) => {
  const pathSegments = url.split("/");
  return pathSegments[pathSegments.length - 1];
};
