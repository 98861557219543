import { SelectChangeEvent } from "@mui/material";
import { useReducer } from "react";
import { IExpenseRequestRow } from "../../../services/API/GetExpenseRequests";
import { IExpenseRequestListRow } from "../../../services/API/GetExpenseRequestList";

export const departments = [
  "Accounting & Finance",
  "Administration & Operations",
  "Customer Service",
  "Human Resources",
  "Information Technology",
  "Marketing & Sales",
  "Research & Development",
  "Underwriting & Claims",
];

interface ITextFieldData {
  editing: boolean;
  expenseRequestID?: number;
  employeeID?: number;
  choice: string;
  department?: string;
  bwipvch?: string;
  requestBy: string;
  date: Date;
  edrNo: string;
  purpose: string;
  dateLastPurchased: Date;
  itemID1: number;
  item1: string;
  quantity1: number;
  unitCost1: number;
  amount1: number;
  itemID2: number;
  item2: string;
  quantity2: number;
  unitCost2: number;
  amount2: number;
  itemID3: number;
  item3: string;
  quantity3: number;
  unitCost3: number;
  amount3: number;
  requestAmount: number;
  requestAmountGST: number;
  preparedBy: string;
  passedBy: string | undefined;
  approvedBy: string | undefined;
  receivedBy: string | undefined;
  token: string;
  requestType: string;
  requestAssigned: string;
  chequeNo: string;
  bank: string;
  extraInfo: string;
  exRequestDocRef?: string;
  rejected?: boolean;
  closed?: boolean;
  receiverSignature?: string;
}

export interface IPaymentState {
  dataToSave: ITextFieldData;
  isDirty: boolean;
}
export const initialState: IPaymentState = {
  dataToSave: {
    editing: false,
    choice: "",
    chequeNo: "N/A",
    bank: "N/A",
    extraInfo: "N/A",
    requestType: "",
    requestAssigned: "",
    department: "", // This will only be used when choice is 'Expenditure'
    requestBy: "",
    date: new Date(), // or new Date() if you prefer a Date object
    edrNo: "N/A",
    purpose: "",
    dateLastPurchased: new Date(), // or new Date() if you prefer a Date object
    itemID1: 0,
    itemID2: 0,
    itemID3: 0,
    item1: "",
    quantity1: 0,
    unitCost1: 0,
    amount1: 0,
    item2: "",
    quantity2: 0,
    unitCost2: 0,
    amount2: 0,
    item3: "",
    quantity3: 0,
    unitCost3: 0,
    amount3: 0,
    requestAmount: 0,
    requestAmountGST: 0,
    preparedBy: "",
    passedBy: "",
    approvedBy: "",
    receivedBy: "",
    token: "",
    bwipvch: "Auto Generated",
  },
  isDirty: false,
};
export type ActionType =
  | {
      type: "UPDATE_FIELD";
      payload: { name: string; value: string | boolean | number };
    }
  | { type: "UPDATE_DROPDOWN"; payload: { name: string; value: string } }
  | {
      type: "UPDATE_EXISTING_USER";
      payload: IExpenseRequestRow;
      listItem: IExpenseRequestListRow[] | [];
    };

export const reducer = (
  state: IPaymentState,
  action: ActionType
): IPaymentState => {
  switch (action.type) {
    case "UPDATE_FIELD":
      return {
        ...state,
        isDirty: true,
        dataToSave: {
          ...state.dataToSave,
          [action.payload.name]: action.payload.value,
        },
      };
    case "UPDATE_DROPDOWN":
      return {
        ...state,
        dataToSave: {
          ...state.dataToSave,
          [action.payload.name]: action.payload.value,
        },
      };
    case "UPDATE_EXISTING_USER":
      return {
        ...state,
        dataToSave: {
          ...state.dataToSave,
          editing: action.payload.closed || action.payload.passedBy !== null,
          expenseRequestID: action.payload.expenseRequestID,
          choice: action.payload.choice,
          department: action.payload.department,
          requestBy: action.payload.requestBy,
          date: action.payload.requestDate,
          edrNo: action.payload.edrNo,
          purpose: action.payload.purpose,
          dateLastPurchased: action.payload.dateLastPurchased,
          requestAmount: action.payload.requestAmount,
          requestAmountGST: action.payload.requestAmountGST,
          preparedBy: action.payload.preparedBy,
          passedBy: action.payload.passedBy,
          receivedBy: action.payload.receivedBy,
          approvedBy: action.payload.approvedBy,
          employeeID: action.payload.employeeID,
          itemID1: action.listItem[0]
            ? action.listItem[0].ExpenseRequestsItemID
            : 0,
          item1: action.listItem[0] ? action.listItem[0].Item : "",
          quantity1: action.listItem[0] ? action.listItem[0].Quantity : 0,
          unitCost1: action.listItem[0] ? action.listItem[0].UnitCost : 0,
          amount1: action.listItem[0] ? action.listItem[0].Amount : 0,
          itemID2: action.listItem[1]
            ? action.listItem[1].ExpenseRequestsItemID
            : 0,
          item2: action.listItem[1] ? action.listItem[1].Item : "",
          quantity2: action.listItem[1] ? action.listItem[1].Quantity : 0,
          unitCost2: action.listItem[1] ? action.listItem[1].UnitCost : 0,
          amount2: action.listItem[1] ? action.listItem[1].Amount : 0,
          itemID3: action.listItem[2]
            ? action.listItem[2].ExpenseRequestsItemID
            : 0,
          item3: action.listItem[2] ? action.listItem[2].Item : "",
          quantity3: action.listItem[2] ? action.listItem[2].Quantity : 0,
          unitCost3: action.listItem[2] ? action.listItem[2].UnitCost : 0,
          amount3: action.listItem[2] ? action.listItem[2].Amount : 0,
          chequeNo: action.payload.chequeNo,
          bank: action.payload.bank,
          extraInfo: action.payload.extraInfo,
          requestType: action.payload.requestType,
          requestAssigned: action.payload.requestAssigned,
          bwipvch: action.payload.bwipVoucher,
          exRequestDocRef: action.payload.exRequestDocRef,
          rejected: action.payload.rejected,
          closed: action.payload.closed,
          receiverSignature: action.payload.receiverSignature,
        },
      };
    default:
      return state;
  }
};

export const PaymentReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({
      type: "UPDATE_FIELD",
      payload: {
        name,
        value: e.target.type === "checkbox" ? e.target.checked : value,
      },
    });
  };

  const handleChangeDropDown = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    dispatch({
      type: "UPDATE_DROPDOWN",
      payload: {
        name,
        value,
      },
    });
  };

  const updateStateForExistingUser = (
    existingUser: IExpenseRequestRow,
    listItem: IExpenseRequestListRow[] | []
  ) => {
    dispatch({ type: "UPDATE_EXISTING_USER", payload: existingUser, listItem });
  };

  return {
    state,
    handleChange,
    handleChangeDropDown,
    updateStateForExistingUser,
  };
};
